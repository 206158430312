import React, { useState, useEffect } from "react";
import { Button, Form, Input } from 'antd';
import api from "../../api";
import '../../index.css';

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const [tokens, updateTokens] = useState([]);

  const fetchData = () => {
    api.fetchFirebase('tokens', updateTokens);
  };

  const handleOnFinish = async (data) => {
    console.log(data)
    /*if(!initialValues.title) {
      const tokensChunks = tokens.reduce((resultArray, token, index) => {
        const chunkIndex = Math.floor(index/100);
      
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
      
        resultArray[chunkIndex].push(token.expo_push_token.data);
      
        return resultArray
      }, []);

      const tokensMessage = tokensChunks.map((tokens) =>(JSON.stringify({
        to: tokens,
        sound: 'default',
        title: data.title,
        body: data.body,
      })
      ))
      
      const requests = tokensMessage.map((chunk) => {
        return fetch('https://exp.host/--/api/v2/push/send', {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
          },
          body: chunk,
        });
      });

      try {
        await Promise.all(requests);
      } catch (err) {
        console.log('err', err);
      }
    }*/
      
    onFinish(data);
  }
  useEffect(() => {
    if (!initialValues.title) {
      fetchData();
    }
  }, []);

  return (<Form
    name="Circulares"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 14 }}
    onFinish={handleOnFinish}
    initialValues={initialValues}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Por favor ingresa el titulo de la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Descripción"
      name="body"
      rules={[{ required: true, message: 'Por favor ingresa la descripción de la noticia' }]}
    >
      <Input.TextArea />
    </Form.Item>

    <Form.Item
      label="Text"
      name="text"
    >
      <Input.TextArea />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
