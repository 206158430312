import { coulmnRendererEllipsis } from "../../utils";

const screenColumnsProvisional = [
  {
    title: 'Descripción',
    dataIndex: 'body',
    key: 'body',
    render: coulmnRendererEllipsis,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Texto extra',
    dataIndex: 'auxText',
    key: 'auxText',
  },
  {
    title: 'Categoría',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Hora',
    dataIndex: 'hour',
    key: 'hour',
  },
];

export default screenColumnsProvisional;
