import firebase from 'firebase/app'
import firebaseConfig from '../firebaseCredentials';
import "firebase/auth";
import "firebase/database";
import { PROYECT_NAME } from '../constants';

// Optionally import the services that you want to use
//import "firebase/firestore";
//import "firebase/functions";
//import "firebase/storage";

export default firebase.initializeApp(firebaseConfig[PROYECT_NAME]?.firebaseCredentials);
