import React from "react";
import screenColumns from "./screenColumns";
import screenColumnsProvisional from "./screenColumnsProvisional";
import ScreenForm from "./ScreenForm";
import ScreenFormProvisional from "./ScreenFormProvisional";
import ScreenBase from "../../common/ScreenBase";
import { DATABASE_REFS, PROYECT_NAME } from "../../constants";

const Schedule = () => (<ScreenBase
  Form={PROYECT_NAME === 'ieee' ? ScreenFormProvisional : ScreenForm}
  screenColumns={PROYECT_NAME === 'ieee' ? screenColumnsProvisional : screenColumns}
  databaseRef={DATABASE_REFS.SCHEDULE}
  title='Programa'
/>);

export default Schedule;
