import React from "react";
import { DesktopOutlined } from '@ant-design/icons';
import News from "../Screens/News";
import Gallery from "../Screens/Gallery";
import Conferences from "../Screens/Conferences";
import Partners from '../Screens/Partners';
import Logos from '../Screens/Logos';
import Notifications from '../Screens/Notifications';
import { PROYECT_NAME } from "../constants";
import firebaseConfig from '../firebaseCredentials';
import Questions from "../Screens/Questions";
import Schedule from "../Screens/Schedule";
import Surveys from "../Screens/Surveys";
import Exhibitors from "../Screens/Exhibitors";
import WelcomeBanner from "../Screens/WelcomeBanner";
import Notes from "../Screens/Notes";
import Stands from "../Screens/Stands";
import Inauguration from "../Screens/Inauguration";
import Weather from "../Screens/Weather";

const routes = [
  {
    key: 'conferences',
    icon: () => <DesktopOutlined />,
    link: '/conferences',
    label: 'Conferencias',
    screen: () => <Conferences />,
  },
  {
    key: 'exhibitors',
    icon: () => <DesktopOutlined />,
    link: '/exhibitors',
    label: 'Conferencias',
    screen: () => <Exhibitors />,
  },
  {
    key: 'gallery',
    icon: () => <DesktopOutlined />,
    link: '/gallery',
    label: 'Galería',
    screen: () => <Gallery />,
  },
  {
    key: 'inauguration',
    icon: () => <DesktopOutlined />,
    link: '/inauguration',
    label: 'Inauguración',
    screen: () => <Inauguration />,
  },
  {
    key: 'logos',
    icon: () => <DesktopOutlined />,
    link: '/logos',
    label: 'Logos',
    screen: () => <Logos />,
  },
  {
    key: 'news',
    icon: () => <DesktopOutlined />,
    link: '/news',
    label: 'Noticias',
    screen: () => <News />,
  },
  {
    key: 'notes',
    icon: () => <DesktopOutlined />,
    link: '/notes',
    label: 'Notas deportivas',
    screen: () => <Notes />,
  },
  {
    key: 'notifications',
    icon: () => <DesktopOutlined />,
    link: '/notifications',
    label: 'Notificaciones',
    screen: () => <Notifications />,
  },
  {
    key: 'questions',
    icon: () => <DesktopOutlined />,
    link: '/questions',
    label: 'Preguntas',
    screen: () => <Questions />,
  },
  {
    key: 'race5k',
    icon: () => <DesktopOutlined />,
    link: '/race5k',
    label: 'Carrera 5k',
    screen: () => <News />,
  },
  {
    key: 'schedule',
    icon: () => <DesktopOutlined />,
    link: '/schedule',
    label: 'Programa',
    screen: () => <Schedule />,
  },
  {
    key: 'stands',
    icon: () => <DesktopOutlined />,
    link: '/stands',
    label: 'Stands',
    screen: () => <Stands />,
  },
  {
    key: 'surveys',
    icon: () => <DesktopOutlined />,
    link: '/surveys',
    label: 'Encuesta',
    screen: () => <Surveys />,
  },
  {
    key: 'sponsors',
    icon: () => <DesktopOutlined />,
    link: '/sponsors',
    label: 'Patrocinadores',
    screen: () => <Partners />,
  },
  {
    key: 'tokens',
    icon: () => <DesktopOutlined />,
    link: '/tokens',
    label: 'Tokens',
    screen: () => <News />,
  },
  {
    key: 'walk25k',
    icon: () => <DesktopOutlined />,
    link: '/walk25k',
    label: 'Caminata 25k',
    screen: () => <News />,
  },
  {
    key: 'welcomebanner',
    icon: () => <DesktopOutlined />,
    link: '/welcomebanner',
    label: 'Banner de bienvenida',
    screen: () => <WelcomeBanner />,
  },
  {
    key: 'weather',
    icon: () => <DesktopOutlined />,
    link: '/weather',
    label: 'Weather',
    screen: () => <Weather />,
  },
].filter((route) => firebaseConfig[PROYECT_NAME].routes.find((routeAv) => routeAv === route.key)).sort((a, b) => {
  const aLower = a.label.toLowerCase();
  const bLower = b.label.toLowerCase();
  if (aLower < bLower) {
    return -1;
  }
  if ( aLower > bLower) {
    return 1;
  }
  return 0;
});

export default routes;
