const screenColumns = [
  {
    title: 'Estatus',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Grados',
    dataIndex: 'value',
    key: 'value',
  },
];

export default screenColumns;
