import React from "react";
import { Button, Form, Input, Switch } from 'antd';
import '../../index.css';

const ScreenForm = ({ onFinish, initialValues = {} }) => {

  return (<Form
    name="Circulares"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 14 }}
    onFinish={onFinish}
    initialValues={initialValues}
  >
    <Form.Item
      label="Nombre"
      name="name"
      rules={[{ required: true, message: 'Por favor ingresa el titulo de la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Descripción"
      name="desc"
      rules={[{ required: true, message: 'Por favor ingresa la descripción de la noticia' }]}
    >
      <Input.TextArea />
    </Form.Item>
    
    <Form.Item
      label="Imagen"
      name="image"
      rules={[{ required: true, message: 'Por favor ingresa la imagen de la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Presentando"
      name="active"
      rules={[{ required: true, message: 'Por favor ingresa el titulo de la noticia' }]}
      valuePropName="active"
    >
      <Switch defaultChecked={initialValues.active} />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
