import React from "react";
import { Button, Form, Input, DatePicker, Switch } from 'antd';
import moment from 'moment';
import '../../index.css';
import { formatDate, formatTime, SCHEDULE_CATEGORIES } from "../../constants";

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const defaultDate = initialValues.date ? moment(initialValues.date, formatDate) : moment();
  const trueInitialValues = { ...initialValues, date: defaultDate, };

  const handleOnFinish = (data) => {
    //const hourRange = `${data.hour[0].format('HH:mm')} – ${data.hour[1].format('HH:mm')}`;
    onFinish({
      ...data,
      date: data.date.date(),
      category: 'Ponentes',
    });
  };

  return (<Form
    name="Circulares"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 14 }}
    onFinish={handleOnFinish}
    initialValues={trueInitialValues}
  >
    <Form.Item
      label="Nombre"
      name="name"
      rules={[{ required: true, message: 'Por favor ingresa el titulo de la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Descripción"
      name="desc"
      rules={[{ required: true, message: 'Por favor ingresa la descripción de la noticia' }]}
    >
      <Input.TextArea />
    </Form.Item>
    

    <Form.Item
      label="Fecha"
      name="date"
      rules={[{ required: true, message: 'Por favor ingresa el día del evento' }]}
    >
      <DatePicker format={formatDate} />
    </Form.Item>

    <Form.Item
      label="Imagen"
      name="image"
      rules={[{ required: true, message: 'Por favor ingresa la imagen de la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Presentando"
      name="active"
      rules={[{ required: true, message: 'Por favor ingresa el titulo de la noticia' }]}
    >
      <Switch defaultChecked={initialValues.active} />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
