import React from "react";
import screenColumns from "./screenColumns";
import ScreenForm from "./ScreenForm";
import ScreenBase from "../../common/ScreenBase";
import { DATABASE_REFS } from "../../constants";

const WelcomeBanner = () => (<ScreenBase
  Form={ScreenForm}
  screenColumns={screenColumns}
  databaseRef={DATABASE_REFS.WELCOME_BANNER}
  title='Banner de bienvenida'
/>);

export default WelcomeBanner;
