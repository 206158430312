const screenColumns = [
  {
    title: 'Marca',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Contraseña',
    dataIndex: 'password',
    key: 'password',
  },
  {
    title: 'Key',
    dataIndex: 'keyName',
    key: 'keyName',
  },
  {
    title: 'Imagen',
    dataIndex: 'image',
    key: 'image',
    render: (image) => (<div>
      <img src={image} width="300" height="200"></img>
    </div>)
  },
];

export default screenColumns;
