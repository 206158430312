const screenColumns = [
  {
    title: 'Imagen',
    dataIndex: 'url',
    key: 'url',
    render: (image) => (<div>
      <img src={image} width="300" height="200"></img>
    </div>)
  },
  {
    title: 'Nivel',
    dataIndex: 'level',
    key: 'level',
  },
];

export default screenColumns;
