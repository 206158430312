import React from "react";
import screenColumns from "./screenColumns";
import QuestionsScreen from "./QuestionsScreen";
import { DATABASE_REFS } from "../../constants";

const Questions = () => (<QuestionsScreen
  screenColumns={screenColumns}
  databaseRef={DATABASE_REFS.QUESTIONS}
  title='Preguntas'
/>);

export default Questions;
