import React from "react";
import screenColumns from "./screenColumns";
import ScreenForm from "./ScreenForm";
import ScreenBase from "../../common/ScreenBase";
import { DATABASE_REFS } from "../../constants";

import screenColumnsPila from "./screenColumnsPila";
import ScreenFormPila from "./ScreenFormPila";

const Notifications = () => (
  <>
    <ScreenBase
      Form={ScreenForm}
      screenColumns={screenColumns}
      databaseRef={DATABASE_REFS.NOTIFICATIONS}
      title="Notificaciones"
    />

  <ScreenBase
      Form={ScreenFormPila}
      screenColumns={screenColumnsPila}
      databaseRef={DATABASE_REFS.NOTIFICATIONSPILA}
      title="Notificaciones pila"
    />
  </>
);

export default Notifications;
