import React, { useState } from "react";
import { Button, Form, Input, Image } from 'antd';
import '../../index.css';
import { formatDate } from "../../constants";

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const [previewImageLarge, updatePreviewLarge] = useState(initialValues.large ? initialValues.large : 'https://www.ghihornos.com/wp-content/uploads/2017/09/default.jpg');
  const [previewImageSmall1, updatePreviewSmall1] = useState(initialValues.small1 ? initialValues.small1 : 'https://www.ghihornos.com/wp-content/uploads/2017/09/default.jpg');
  const [previewImageSmall2, updatePreviewSmall2] = useState(initialValues.small2 ? initialValues.small2 : 'https://www.ghihornos.com/wp-content/uploads/2017/09/default.jpg');

  const callbackHandler = {
    'large': updatePreviewLarge,
    'small1': updatePreviewSmall1,
    'small2': updatePreviewSmall2,
  }

  const handleUpdatePreviews = (data) => {
    const [item] = data;
    const [nameField] = item.name;
    callbackHandler[nameField](item.value);
  }

  return (<Form
    name="Gallery"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 14 }}
    onFinish={onFinish}
    onFieldsChange={handleUpdatePreviews}
    initialValues={initialValues}
  >
    
    <Form.Item
      label="Imagen Larga"
      name="large"
      rules={[{ required: true, message: 'Por favor ingresa la imagen de la noticia' }]}
    >
      <Input />
    </Form.Item>
    <Image
      width={200}
      src={previewImageLarge}
    />

    <Form.Item
      label="Imagen Small 1"
      name="small1"
      rules={[{ required: true, message: 'Por favor ingresa la imagen de la noticia' }]}
    >
      <Input />
    </Form.Item>
    <Image
      width={200}
      src={previewImageSmall1}
    />

    <Form.Item
      label="Imagen Small 2"
      name="small2"
      rules={[{ required: true, message: 'Por favor ingresa la imagen de la noticia' }]}
    >
      <Input />
    </Form.Item>
    <Image
      width={200}
      src={previewImageSmall2}
    />

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
