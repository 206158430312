import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { Layout, Menu } from 'antd';
import Login from '../Screens/Login';
import routes from "./routes";

const { Header, Content, Footer, Sider } = Layout;

const Home = () => (<h2>Home</h2>);

const RoutesMenu = () => {
  const{ pathname } = useLocation();
  const currentKey = pathname.replace('/', '');
  return (<Menu theme="dark" defaultSelectedKeys={[currentKey]} mode="inline">
    {routes.map(({ key, icon, link, label }) => (
      <Menu.Item key={key} icon={icon()}>
        <Link to={link}>{label}</Link>
      </Menu.Item>
    ))}
  </Menu>);
}

const RootRouter = () => {
  const [collapsed, updateCollapsed] = useState(false);
  const loggedIn = true;

  const onCollapse = collapsed => {
    updateCollapsed(collapsed);
  };

  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Header className="site-layout-background" />
        <Layout className="site-layout">
          {loggedIn && <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <RoutesMenu />
          </Sider>}
          <Content style={{ margin: '0 16px' }}>
            <Switch>
              {routes.map(({ link, screen, key }) => (<Route key={`route_${key}`} path={link}>
                {screen()}
              </Route>))}
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Content>
          </Layout>
        <Footer style={{ textAlign: 'center', padding: 0 }}>Admin Apps, The Three</Footer>
      </Layout>
    </Router>
  );
}

export default RootRouter;
