import React from "react";
import { Button, Form, Input, DatePicker, Select, TimePicker } from 'antd';
import moment from 'moment';
import '../../index.css';
import { formatDate, formatTime, SCHEDULE_CATEGORIES } from "../../constants";

const { Option } = Select;

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const defaultDate = initialValues.date ? moment(initialValues.date, formatDate) : moment();
  const defaultHour = initialValues.hour ? moment(initialValues.hour, formatTime) : moment('00:00', formatTime)
  // const [startHour, endHour] = initialValues.hour ? initialValues.hour.split(' – ') : ['00:00', '00:00'];
  // hour: [moment(startHour, 'HH:mm'), moment(endHour, 'HH:mm')]
  const trueInitialValues = { ...initialValues, date: defaultDate, hour: defaultHour, };

  const handleOnFinish = (data) => {
    //const hourRange = `${data.hour[0].format('HH:mm')} – ${data.hour[1].format('HH:mm')}`;
    onFinish({
      ...data,
      date: data.date.date(),
      //hour: hourRange,
      hour: data.hour.format('HH:mm'),
    });
  };

  return (<Form
    name="Schedule"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 14 }}
    onFinish={handleOnFinish}
    initialValues={trueInitialValues}
  >
    <Form.Item
      label="Descripción"
      name="title"
      rules={[{ required: true, message: 'Por favor ingresa la descripción del evento' }]}
    >
      <Input.TextArea />
    </Form.Item>

    <Form.Item
      label="Fecha"
      name="date"
      rules={[{ required: true, message: 'Por favor ingresa el día del evento' }]}
    >
      <DatePicker format={formatDate} />
    </Form.Item>
    
    <Form.Item
      label="Categoría"
      name="category"
      rules={[{ required: true, message: 'Por favor selecciona una categoría' }]}
    >
      <Select placeholder="Selecciona una categoría" defaultDate={initialValues.category}>
        {SCHEDULE_CATEGORIES.map((category) => (
          <Option value={category}>{category}</Option>
        ))}
      </Select>
    </Form.Item>
    
    {/*<Form.Item
      label="Hora"
      name="hour"
      rules={[{ required: true, message: 'Por favor ingresa el rango de tiempo' }]}
    >
      <TimePicker.RangePicker
        format={formatTime}
      />
    </Form.Item>*/}

    <Form.Item
      label="Hora"
      name="hour"
      rules={[{ required: true, message: 'Por favor ingresa la hora' }]}
    >
      <TimePicker
        defaultValue={trueInitialValues.hour}
        format={formatTime}
      />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
