import React from "react";
import { Button, Form, Input, DatePicker } from 'antd';
import moment from 'moment';
import '../../index.css';
import { formatDate } from "../../constants";

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const defaultDate = initialValues.date ? moment(initialValues.date, formatDate) : moment();

  const handleOnFinish = (data) => {
    if (!data.video) {
      delete data.video;
    }

    onFinish({ ...data, date: data.date.format(formatDate)});
  };
  const trueInitialValues = { ...initialValues, date: defaultDate };

  return (<Form
    name="Circulares"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 14 }}
    onFinish={handleOnFinish}
    initialValues={trueInitialValues}
  >
    <Form.Item
      label="Título"
      name="title"
      rules={[{ required: true, message: 'Por favor ingresa el titulo de la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Descripción"
      name="desc"
      rules={[{ required: true, message: 'Por favor ingresa la descripción de la noticia' }]}
    >
      <Input.TextArea />
    </Form.Item>
    
    <Form.Item
      label="Video"
      name="video"
      rules={[{ message: 'Por favor ingresa el url del video de la noticia' }]}
    >
      <Input />
    </Form.Item>
    
    <Form.Item
      label="Imagen"
      name="image"
      rules={[{ required: true, message: 'Por favor ingresa la imagen de la noticia' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Fecha"
      name="date"
      rules={[{ required: true, message: 'Por favor ingresa el día de la noticia' }]}
    >
      <DatePicker format={formatDate} />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
