import { Row, Col, Switch } from 'antd';

const screenColumns = [
  {
    title: 'Pregunta',
    dataIndex: 'question',
    key: 'question',
  },
  {
    title: 'Opciones',
    dataIndex: 'options',
    key: 'options',
    render: (options) => {
      return (<Col>
        {Object.keys(options).map((key) => {
          return (
          <Row>{options[key].option}</Row>
        )})}
      </Col>);
    }
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (active) => (<div>
      <Switch checked={active} />
    </div>),
  },
];

export default screenColumns;
