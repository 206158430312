import { coulmnRendererEllipsis } from "../../utils";

const screenColumns = [
  {
    title: 'Titulo',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Descripción',
    dataIndex: 'desc',
    key: 'desc',
    render: coulmnRendererEllipsis,
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'video',
    render: (video) => (<div>
      <span>{video}</span>
      <iframe src={video} width="300" height="200" allowfullscreen></iframe>
    </div>)
  },
  {
    title: 'Imagen',
    dataIndex: 'image',
    key: 'image',
    render: (image) => (<div>
      <span>{image}</span>
      <img src={image} width="300" height="200"></img>
    </div>)
  },
];

export default screenColumns;
