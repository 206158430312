const domains = {
  congreso: {
    firebaseCredentials: {
      apiKey: "AIzaSyDRPuLkHIMoUnMHfAvCPBlBaIbofy6ySWk",
      authDomain: "congresoapp-90bb1.firebaseapp.com",
      databaseURL: "https://congresoapp-90bb1.firebaseio.com",
      projectId: "congresoapp-90bb1",
      storageBucket: "congresoapp-90bb1.appspot.com",
      messagingSenderId: "120825405139",
      appId: "1:120825405139:web:aedf1897f7fcf858",
    },
    notifications: {
      apiUrl: 'http://foro.admin-apps.thethree.mx/api/notifications'
    },
    routes: [
      'exhibitors',
      'gallery',
      'news',
      'notifications',
      'questions',
      'schedule',
      'logos',
      'surveys',
    ],
  },
  caname: {
    firebaseCredentials: {
      apiKey: "AIzaSyCAtdCTIzIZ8-rmTgJM7MkFcBAksK-dOfA",
      authDomain: "caname-app.firebaseapp.com",
      databaseURL: "https://caname-app.firebaseio.com",
      projectId: "caname-app",
      storageBucket: "caname-app.appspot.com",
      messagingSenderId: "253303015797",
      appId: "1:253303015797:web:880749a9b0b5ef6a956b3c",
      measurementId: "G-1WBNJ8DW6D"
    },
    notifications: { 
      apiUrl: 'http://api.simposiumenergia.org.mx/notifications',
      test: ''
    },
    routes: [
      'exhibitors',
      'notifications',
      'questions',
      'schedule',
      'logos',
    ],
  },
  ieee: {
    firebaseCredentials: {
      apiKey: "AIzaSyAT4Zv5IMiZqRg6sQwd51ujuT4jiP1Ko7w",
      authDomain: "ieee-app-f3e10.firebaseapp.com",
      databaseURL: "https://ieee-app-f3e10-default-rtdb.firebaseio.com",
      projectId: "ieee-app-f3e10",
      storageBucket: "ieee-app-f3e10.appspot.com",
      messagingSenderId: "917943491875",
      appId: "1:917943491875:web:e04b38b0c433fa1c824096",
      measurementId: "G-0M0R1F2WSR",
    },
    routes: [
      'dates',
      'logos',
      'notifications',
      'schedule',
      'inauguration',
    ],
  },
  foro: {
    firebaseCredentials: {
      apiKey: "AIzaSyCduzmrFJ3TFXsVF9tMfWZ9CmFppAh9D6Q",
      authDomain: "app-foro-2019.firebaseapp.com",
      databaseURL: "https://app-foro-2019.firebaseio.com",
      projectId: "app-foro-2019",
      storageBucket: "app-foro-2019.appspot.com",
      messagingSenderId: "748386251612",
      appId: "1:748386251612:web:be65ab9046244d6a",
    },
    notifications: {
      apiUrl: 'http://foro.admin-apps.thethree.mx/api/notifications'
    },
    routes: [
      'exhibitors',
      'gallery',
      'logos',
      'news',
      'notes',
      'notifications',
      'questions',
      'race5k',
      'schedule',
      'stands',
      'surveys',
      'tokens',
      'walk25k',
      'welcomebanner',
    ],
  },
  premio: {
    firebaseCredentials: {
      apiKey: "AIzaSyCM-QoEwe0mHtYhFLrn-z_h-mk1Lj5k060",
      authDomain: "premioapp2018.firebaseapp.com",
      databaseURL: "https://premioapp2018.firebaseio.com",
      projectId: "premioapp2018",
      storageBucket: "premioapp2018.appspot.com",
      messagingSenderId: "135306882916",
      appId: "1:135306882916:web:3e62ffaabb3ec316cb9471"
    },
    notifications: {
      apiUrl: 'http://foro.admin-apps.thethree.mx/api/notifications'
    },
    routes: [
      'exhibitors',
      'gallery',
      'logos',
      'news',
      'notes',
      'notifications',
      'questions',
      'race5k',
      'schedule',
      'stands',
      'surveys',
      'tokens',
      'walk25k',
      'welcomebanner',
    ],
  },
  canilec: {
    firebaseCredentials: {
      apiKey: "AIzaSyCexh6QWy2-HAT5xmPZVPJPWeLRXNn0K30",
      authDomain: "canilecapp.firebaseapp.com",
      databaseURL: "https://canilecapp.firebaseio.com",
      projectId: "canilecapp",
      storageBucket: "canilecapp.appspot.com",
      messagingSenderId: "190088465586",
      appId: "1:190088465586:web:cce52d8b928dd559",
    },
    notifications: {
      apiUrl: 'http://canilec.admin-apps.thethree.mx/api/notifications_canilec'
    },
    routes: [
      'exhibitors',
      'notifications',
      'schedule',
      'logos',
      'weather',
    ],
  },
  localhost: {
    firebaseCredentials: {
      apiKey: "AIzaSyCexh6QWy2-HAT5xmPZVPJPWeLRXNn0K30",
      authDomain: "canilecapp.firebaseapp.com",
      databaseURL: "https://canilecapp.firebaseio.com",
      projectId: "canilecapp",
      storageBucket: "canilecapp.appspot.com",
      messagingSenderId: "190088465586",
      appId: "1:190088465586:web:cce52d8b928dd559",
    },
    routes: [
      'exhibitors',
      'notifications',
      'schedule',
      'logos',
      'weather',
    ],
  },
};

export default domains;
