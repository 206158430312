export const DATABASE_REFS = {
  CONFERENCES: 'conferences',
  EXAMPLE: 'example',
  EXHIBITORS: 'exhibitors',
  GALLERY: 'gallery',
  INAUGURATION: 'inauguration',
  LOGOS: 'logos',
  NEWS: 'news',
  NOTES: 'notes',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONSPILA: 'notificationspila',
  QUESTIONS: 'questions',
  SCHEDULE: 'schedule',
  SPONSORS: 'sponsors',
  STANDS: 'stands',
  SURVEYS: 'surveys',
  USERS: 'users',
  WELCOME_BANNER: 'welcomebanner',
  WEATHER: 'weather',
};

export const MODAL_ACTIONS = {
  NONE: 'none',
  EDIT: 'edit',
  DELETE: 'delete',
  ADD: 'add',
};

export const QUESTIONS_STATUS = {
  APPROVED: 'aprobada',
  REJECTED: 'rechazada',
  ANSWERED: 'contestada',
  PENDING: 'pendiente',
};

export const QUESTIONS_BUTTONS_DATA = {
  [QUESTIONS_STATUS.PENDING]: {
    primary: {
      label: 'Aprobar',
      setTo: QUESTIONS_STATUS.APPROVED,
    },
    secondary: {
      label: 'Rechazar',
      setTo: QUESTIONS_STATUS.REJECTED,
    }
  },
  [QUESTIONS_STATUS.APPROVED]: {
    primary: {
      label: 'Marcar como respondida',
      setTo: QUESTIONS_STATUS.ANSWERED,
    },
    secondary: {
      label: 'Rechazar',
      setTo: QUESTIONS_STATUS.REJECTED,
    },
  },
  [QUESTIONS_STATUS.ANSWERED]: {
    primary: {
      label: 'Recuperar',
      setTo: QUESTIONS_STATUS.PENDING,
    },
    secondary: {
      label: 'Rechazar',
      setTo: QUESTIONS_STATUS.REJECTED,
    }
  },
  [QUESTIONS_STATUS.REJECTED]: {
    primary: {
      label: 'Recuperar',
      setTo: QUESTIONS_STATUS.PENDING,
    },
    secondary: {
      label: 'Aprobar',
      setTo: QUESTIONS_STATUS.APPROVED,
    }
  },
};

export const SCHEDULE_CATEGORIES = [
  0,
  1,
];

const parts = window.location.hostname.split('.');
export const PROYECT_NAME = parts[0] === 'wwww' ? parts[1] : parts[0];

export const formatDate = 'DD/MM/YYYY';
export const formatTime = 'HH:mm';
