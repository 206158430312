const screenColumns = [
  {
    title: 'Imagen 1',
    dataIndex: 'large',
    key: 'large',
    render: (image) => (<div>
      <img src={image} width="300" height="200"></img>
    </div>)
  },
  {
    title: 'Imagen 2',
    dataIndex: 'small1',
    key: 'small1',
    render: (image) => (<div>
      <img src={image} width="300" height="200"></img>
    </div>)
  },
  {
    title: 'Imagen 3',
    dataIndex: 'small2',
    key: 'small2',
    render: (image) => (<div>
      <img src={image} width="300" height="200"></img>
    </div>)
  },
];

export default screenColumns;
