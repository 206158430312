import firebase from './firebase';
import throwDefaultError, { missingFields, validateEmail } from '../errorHandler';

const defaultSuccessCB = () => {};

class Api {
  constructor() {
    this.db = firebase.database();
    this.auth = firebase.auth();
  }

  defaultFetch = (collection) => {
    const rows = [];
    
    if (collection && collection.val()) {        
        collection.forEach((obj)=>{
        let item = obj.val();
        item.key = obj.key;
        rows.push(item);
      });
    }

    return rows;
  };

  fetchFirebase = (dbRef, successCB, errorCB = throwDefaultError) => {
    this.db.ref(dbRef)
    .once('value',(data)=>{
      successCB( this.defaultFetch(data) );
    }, error => {
      errorCB(error);
    });
  };

  fetchRealtime = (dbRef, successCB, errorCB = throwDefaultError) => {
    this.db.ref(dbRef)
    .on('value',(data)=>{
      successCB( this.defaultFetch(data) );
    }, error => {
      errorCB(error);
    });
  };

  add = (dbRef, data, successCB = defaultSuccessCB, errorCB = throwDefaultError) => {
    this.db.ref(dbRef)
    .push(data,(error) => {
      if (error) {
        errorCB(error);
        return;
      }
      successCB();
    });
  };

  edit = (dbRef, data, successCB = defaultSuccessCB, errorCB = throwDefaultError) => {
    const { key, ...restData } = data;
    const url = `${dbRef}/${key}`;
    this.db.ref(url)
    .set(restData,(error) => {
      if (error) {
        errorCB(error);
        return;
      }
      successCB();
    });
  };
  
  delete = (dbRef, key, successCB = defaultSuccessCB, errorCB = throwDefaultError) => {
    const url = `${dbRef}/${key}`;
    this.db.ref(url)
    .remove((error) => {
      if (error) {
        errorCB(error);
        return;
      }
      successCB();
    });
  };

  login = async ({ email, password }, callback) => {
    const fieldsMissing = missingFields('login', email, password);
    if (fieldsMissing) {
      return;
    }

    const isValidEmail = validateEmail(email);
    if (!isValidEmail) {
      return;
    }
  
    try {
      await this.auth.signInWithEmailAndPassword(email, password);
      callback();
    } catch (error) {
      throwDefaultError(error.message);
    }
    
  };

  logout = (callback) => {
    this.auth.signOut();
    callback();
  }
  
}

export default new Api();
