import React, { useState } from "react";
import { Button, Form, Input, Image, InputNumber } from 'antd';
import moment from 'moment';
import '../../index.css';

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const handleOnFinish = (data) => {
    const key = initialValues.key ? initialValues.key : `logo_${moment().format('x')}`;
    onFinish({ key, ...data });
  }

  return (<Form
    name="Partners"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 14 }}
    onFinish={handleOnFinish}
    initialValues={initialValues}
  >
    
    <Form.Item
      label="Estatus"
      name="status"
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Grados"
      name="value"
    >
      <InputNumber />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
