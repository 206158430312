const screenColumns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Empresa',
    dataIndex: 'bussines',
    key: 'bussines',
  },
  {
    title: 'Puesto',
    dataIndex: 'position',
    key: 'position',
  },
  {
    title: 'Pregunta',
    dataIndex: 'question',
    key: 'question',
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    key: 'status',
  },
];

export default screenColumns;
