import { Button, Popconfirm, message } from "antd";
import { PROYECT_NAME } from '../../constants';
import firebaseConfig from '../../firebaseCredentials';

const confirm = async ({ body, title }) => {
  console.log(body, title);
  try {
    const fetchResponse = await fetch(firebaseConfig[PROYECT_NAME]?.notifications?.apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        titulo: title ?? "",
        mensaje: body ?? "",
      }),
    });
    const data = await fetchResponse.json();
    console.log(data);
    message.success("Se ha enviado la notificación");
  } catch (e) {
    message.error(`error`);
  }
};

const cancel = (e) => {
  console.log(e);
};
const screenColumns = [
  {
    title: "Titulo",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Descripción",
    dataIndex: "body",
    key: "body",
  },
  {
    title: "Texto",
    dataIndex: "text",
    key: "text",
  },
  {
    title: " ",
    dataIndex: "body",
    key: "body",
    render: (...[v, r]) => {
      return (
        <Popconfirm
          title="¿Estas seguro de enviar la notificación?"
          onConfirm={() => confirm(r)}
          onCancel={cancel}
          okText="Enviarla"
          cancelText="Cancelar"
        >
          <Button type="dashed">Enviar</Button>
        </Popconfirm>
      );
    },
  },
];

export default screenColumns;
