import { coulmnRendererEllipsis } from "../../utils";

const screenColumns = [
  {
    title: 'Titulo',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Descripción',
    dataIndex: 'desc',
    key: 'desc',
    render: coulmnRendererEllipsis,
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Sección',
    dataIndex: 'section',
    key: 'section',
  },
];

export default screenColumns;
