import React, { useState } from "react";
import { Button, Form, Input, Image, InputNumber } from 'antd';
import moment from 'moment';
import '../../index.css';

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const [previewImage, updatePreview] = useState(initialValues.url ? initialValues.url : 'https://www.ghihornos.com/wp-content/uploads/2017/09/default.jpg');

  const handleOnFinish = (data) => {
    const key = initialValues.key ? initialValues.key : `logo_${moment().format('x')}`;
    onFinish({ key, ...data });
  }

  const handleImageUpdate = (fields) => {
    const { name, value } = fields[0];
    if (name[0] === 'image') {
      updatePreview(value);
    }
  }

  return (<Form
    name="Partners"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 14 }}
    onFinish={handleOnFinish}
    initialValues={initialValues}
    onFieldsChange={handleImageUpdate}
  >
    
    <Form.Item
      label="Imagen"
      name="url"
      rules={[{ required: true, message: 'Por favor ingresa el logo del patrocinador' }]}
    >
      <Input onChange={updatePreview}/>
    </Form.Item>
    
    <Image
      width={200}
      src={previewImage}
    />

    <Form.Item
      label="Nivel"
      name="level"
      rules={[{ required: true, message: 'Por favor ingresa el titulo de la noticia' }]}
    >
      <InputNumber />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
