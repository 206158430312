import React, { useState } from "react";
import { Button, Form, Input, Divider, Select, Switch } from 'antd';
import '../../index.css';
import { formatDate, formatTime, SCHEDULE_CATEGORIES } from "../../constants";

const { Option } = Select;

const ScreenForm = ({ onFinish, initialValues = {} }) => {
  const getArrayOptions = () => {
    if(!initialValues.options) {
      return [];
    }
    return Object.keys(initialValues.options).map((key) => ({ ...initialValues.options[key], key }));
  }

  const [options, updateOptions] = useState(getArrayOptions());

  const handleOnFinish = (data) => {
    onFinish({
      ...data,
      options,
    });
  };

  const handleAddOption = () => {
    updateOptions([
      ...options,
      {
        key: `opt_${new Date().getMilliseconds()}`,
        option: '',
      }
    ])
  };
  
  const handleUpdateData = (key) => ({ target }) => {
    updateOptions([
      ...options.map((opt) => opt.key !== key ? opt : { ...opt, option: target.value }),
    ]);
  };
  
  const handleRemoveOption = (key) => () => {
    updateOptions([
      ...options.filter((opt) => opt.key !== key),
    ])
  };

  return (<Form
    name="Surveys"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 14 }}
    onFinish={handleOnFinish}
    initialValues={initialValues}
  >
    <Form.Item
      label="Pregunta"
      name="question"
      rules={[{ required: true, message: 'Por favor ingresa la descripción del evento' }]}
    >
      <Input />
    </Form.Item>

    <Divider />
      <Button type="primary" onClick={handleAddOption}>
        Agregar
      </Button>
  
      <div style={{ maxHeight: '150px', overflow: 'auto'}}>
        {options.map((opt) => (<Form.Item key={`option_${opt.key}`} label="Opción de encuesta">
          <Input defaultValue={opt.option} onChange={handleUpdateData(opt.key)}  />
          <Button danger onClick={handleRemoveOption(opt.key)}>
            Remover opción
          </Button>
        </Form.Item>))}
      </div>

    <Divider />

    <Form.Item
      label="Activa"
      name="active"
      rules={[{ required: true, message: 'Por favor ingresa el titulo de la noticia' }]}
    >
      <Switch defaultChecked={initialValues.active} />
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
      <Button type="primary" htmlType="submit">
        Aceptar
      </Button>
    </Form.Item>
  </Form>);
};

export default ScreenForm;
