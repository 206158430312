import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Row, Col, Divider, Typography } from 'antd';
import api from "../../api";
import { MODAL_ACTIONS } from "../../constants";
import ConfirmationDeleteMessage from "../../common/ConfirmationDeleteMessage";

const { Title } = Typography;

const ScreenBase = ({ Form, screenColumns, title, databaseRef }) => {
  const [loading, updateLoading] = useState(false);
  const [modalAction, updateModalAction] = useState(MODAL_ACTIONS.NONE);
  const [modalData, updateModalData] = useState({});
  const [data, updateData] = useState([]);

  const fetchData = () => {
    api.fetchFirebase(databaseRef, updateData);
  };

  const openModal = (action, item) => () => {
    updateModalAction(action);
    updateModalData(item);
  };

  const closeModal = () => {
    updateLoading(false);
    updateModalAction(MODAL_ACTIONS.NONE);
    fetchData();
  }

  const callFirebase = (method, alertMessage, reqData) => {
    updateLoading(true);
    api[method](databaseRef, reqData, () => {
      alert(alertMessage);
      closeModal();
    });
  }

  const cancelFooter = [
    <Button key="back" danger onClick={closeModal}>
      Cancelar
    </Button>
  ];

  const deleteFooter = [
    <Button key="back" danger onClick={closeModal}>
      Cancelar
    </Button>,
    <Button key="ok" type="primary" onClick={() => callFirebase(MODAL_ACTIONS.DELETE, '¡Datos eliminados!', modalData.key)}>
      Aceptar
    </Button>
  ];

  const MODAL_BODIES = {
    [MODAL_ACTIONS.ADD]: {
      title: 'Agregar',
      body: () => (<Form
        onFinish={(formData) => callFirebase(MODAL_ACTIONS.ADD, '¡Datos agregados!', formData)}
      />),
      footer: cancelFooter,
    },
    [MODAL_ACTIONS.EDIT]: {
      title: 'Editar',
      body: () => (<Form
        onFinish={(formData) => callFirebase(MODAL_ACTIONS.EDIT, '¡Datos actualizados!', { ...formData, key: modalData.key })}
        initialValues={modalData}
      />),
      footer: cancelFooter,
    },
    [MODAL_ACTIONS.DELETE]: {
      title: 'Eliminar',
      body: () => (<ConfirmationDeleteMessage current={modalData.current} />),
      footer: deleteFooter,
    },
  };

  const columns = [
    ...screenColumns,
    {
      title: 'Editar',
      key: 'edit',
      dataIndex: 'key',
      render: (key, item) => (
        <Button type="primary" size="large" onClick={openModal(MODAL_ACTIONS.EDIT, item)}>
          Editar
        </Button>
      ),
    },
    {
      title: 'Eliminar',
      key: 'delete',
      dataIndex: 'key',
      render: (key, item) => {
        return (
          <Button type="primary" size="large" danger onClick={openModal(MODAL_ACTIONS.DELETE, { key, current: item.title })}>
            Eliminar
          </Button>);
      },
    }
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const isModalOpen = modalAction !== MODAL_ACTIONS.NONE;
  const currentModalType = MODAL_BODIES[modalAction];
  return (<div>
    <Row align="middle">
      <Col span={20}>
        <Title level={2}>{title}</Title>
      </Col>
      <Col push={1} span={4}>
        <Button type="primary" size="large" onClick={openModal(MODAL_ACTIONS.ADD)}>
          Agregar
        </Button>
      </Col>
    </Row>
    <Divider />
    <Table dataSource={data} columns={columns} />
    {isModalOpen && <Modal
      onCancel={closeModal}
      title={currentModalType.title}
      visible={isModalOpen}
      confirmLoading={loading}
      footer={currentModalType.footer}
    >
      {currentModalType.body()}
    </Modal>}
  </div>);
}

export default ScreenBase;
