import React, { useEffect, useState } from "react";
import { Button, Table, Row, Col, Divider, Typography, Select } from 'antd';
import api from "../../api";
import { MODAL_ACTIONS, QUESTIONS_STATUS, QUESTIONS_BUTTONS_DATA } from "../../constants";

const { Title } = Typography;
const { Option } = Select;

const QuestionsScreen = ({ screenColumns, title, databaseRef }) => {
  const [statusSelected, updateStatusSelected] = useState(QUESTIONS_STATUS.PENDING);
  const [data, updateData] = useState([]);

  const fetchData = () => {
    api.fetchRealtime(databaseRef, updateData);
  };

  const callFirebase = (method, alertMessage, reqData) => {
    api[method](databaseRef, reqData, () => {
      alert(alertMessage);
      fetchData();
    });
  }

  const updateAnswerStatus = (item) => () => callFirebase(MODAL_ACTIONS.EDIT, '¡Datos actualizados!', item);

  const columns = [
    ...screenColumns,
    {
      title: '',
      key: 'edit',
      dataIndex: 'key',
      render: (key, item) => (
        <Button type="primary" size="large" onClick={updateAnswerStatus({
          ...item,
          status: QUESTIONS_BUTTONS_DATA[statusSelected].primary.setTo,
          key,
        })}>
          {QUESTIONS_BUTTONS_DATA[statusSelected].primary.label}
        </Button>
      ),
    },
    {
      title: '',
      key: 'delete',
      dataIndex: 'key',
      render: (key, item) => (
        <Button type="primary" size="large" danger onClick={updateAnswerStatus({
          ...item,
          status: QUESTIONS_BUTTONS_DATA[statusSelected].secondary.setTo,
          key,
        })}>
          {QUESTIONS_BUTTONS_DATA[statusSelected].secondary.label}
        </Button>
      ),
    }
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const filteredQuestions = data.filter(({ status }) => status === statusSelected);
  return (<div>
    <Row align="middle">
      <Col span={20}>
        <Title level={2}>{title}</Title>
      </Col>
      <Col push={1} span={4}>
        <Title level={4}>Filtro de preguntas</Title>
        <Select defaultValue={QUESTIONS_STATUS.PENDING}  onChange={updateStatusSelected}>
          <Option value={QUESTIONS_STATUS.PENDING}>{QUESTIONS_STATUS.PENDING}</Option>
          <Option value={QUESTIONS_STATUS.APPROVED}>{QUESTIONS_STATUS.APPROVED}</Option>
          <Option value={QUESTIONS_STATUS.ANSWERED}>{QUESTIONS_STATUS.ANSWERED}</Option>
          <Option value={QUESTIONS_STATUS.REJECTED}>{QUESTIONS_STATUS.REJECTED}</Option>
        </Select>
      </Col>
    </Row>
    <Divider />
    <Table dataSource={filteredQuestions} columns={columns} />
  </div>);
}

export default QuestionsScreen;
