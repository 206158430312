import { coulmnRendererEllipsis } from "../../utils";

const screenColumns = [
  {
    title: 'Titulo',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Descripción',
    dataIndex: 'body',
    key: 'body',
    render: coulmnRendererEllipsis,
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Lugar',
    dataIndex: 'place',
    key: 'place',
  },
];

export default screenColumns;
