const screenColumns = [
  {
    title: 'Imagen',
    dataIndex: 'image',
    key: 'image',
    render: (image) => (<div>
      <img src={image} width="300" height="200"></img>
    </div>)
  },
];

export default screenColumns;
