import { coulmnRendererEllipsis } from "../../utils";

const screenColumns = [
  {
    title: 'Descripción',
    dataIndex: 'title',
    key: 'title',
    render: coulmnRendererEllipsis,
  },
  {
    title: 'Categoría',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Hora',
    dataIndex: 'hour',
    key: 'hour',
  },
];

export default screenColumns;
