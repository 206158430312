import { Switch } from 'antd';
import { coulmnRendererEllipsis } from '../../utils';

const screenColumns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Descripción',
    dataIndex: 'desc',
    key: 'desc',
    render: coulmnRendererEllipsis,
  },
  {
    title: 'Imagen',
    dataIndex: 'image',
    key: 'image',
    render: (image) => (<div>
      <img src={image} width="300" height="200"></img>
    </div>)
  },
  {
    title: 'Presentando',
    dataIndex: 'active',
    key: 'active',
    render: (active) => (<div>
      <Switch defaultChecked={active} />
    </div>)
  },
];

export default screenColumns;
