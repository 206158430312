import React, { useState, useEffect } from "react";
import { Button, Form, Input, DatePicker, TimePicker } from "antd";
import api from "../../api";
import "../../index.css";
import moment from 'moment';
import firebase from 'firebase';
import { formatDate, formatTime } from "../../constants";


const ScreenForm = ({ onFinish, initialValues = {} }) => {

  const defaultDate = initialValues.date ? moment(initialValues.date, formatDate) : moment();
  const defaultHour = initialValues.hour ? moment(initialValues.hour, formatTime) : moment('00:00', formatTime)

  const trueInitialValues = { ...initialValues, date: defaultDate, hour: defaultHour, };
  
  const [tokens, updateTokens] = useState([]);

  const fetchData = () => {
    api.fetchFirebase("tokens", updateTokens);
  };

  const handleOnFinish = async (data) => {
    console.log(data);

    onFinish({
      ...data,
      date: data.date.format(formatDate),
      hour: data.hour.format('HH:mm'),
    });
  };
  useEffect(() => {
    if (!initialValues.title) {
      fetchData();
    }
  }, []);

  return (
    <Form
      name="notispila"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      onFinish={handleOnFinish}
      initialValues={trueInitialValues}
    >
      <Form.Item
        label="Título"
        name="title"
        rules={[
          {
            required: true,
            message: "Por favor ingresa el titulo de la noticia",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Descripción"
        name="body"
        rules={[
          {
            required: true,
            message: "Por favor ingresa la descripción de la noticia",
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
      label="Fecha"
      name="date"
      rules={[{ required: true, message: 'Por favor ingresa el día del evento' }]}
    >
      <DatePicker format={formatDate} />
    </Form.Item>

    <Form.Item
      label="Hora"
      name="hour"
      rules={[{ required: true, message: 'Por favor ingresa la hora' }]}
    >
      <TimePicker
        defaultValue={trueInitialValues.hour}
        format={formatTime}
      />
    </Form.Item>

      <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
        <Button type="primary" htmlType="submit">
          Aceptar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ScreenForm;
